import { getPastDate } from "./datetime-helper";
import { twoDecimalPlaces } from "./number-helper";

/**
   * @description Returns formatted price for given string to two decimal places, with currency symbol
 */
export const formatPrice = (price: any) => {
  // price = Math.round(price);

  let price1 = Intl.NumberFormat(undefined, {
    currency: "GBP",
    minimumFractionDigits: 2,
    style: 'currency',
  }).format((price ? price : 0))

  return price1;
}

/**
  * @description Returns the VAT price applied to a given price
*/
export const getAppliedVatPrice = (price: number, vatRate: number): number => {
  return price * (vatRate / 100);
}

/**
   * @description Returns the price for a given number with the VAT rate applied
 */
 export const applyVAT = (price: number, vatRate: string): number => {
  const rate = parseFloat(vatRate);

  if (isNaN(rate)) {
    const error = "VAT rate is not parsing correctly";
    throw new RangeError(error);
  }

  const total = price + (price * rate / 100);
  return twoDecimalPlaces(total);
}


/**
   * @description Returns boolean value to specify if product is on sale
 */
export const isOnSale = (salePrice: number, price: number): boolean => {
  if (!salePrice || salePrice === 0 || !price) return false;

  return salePrice < price;
}

/**
   * @description Returns boolean value to specify if product has been published within a given timeframe
 */
export const isPublishedWithinRange = (publicationDate: string, daysAgo: number) => {
  return publicationDate > getPastDate(daysAgo);
}
